import React from 'react';
import Section from './Section';
import ProjectSection from './content/ProjectSection';
import ScientificResearch from './content/ScientificResearch';
import Connect from './content/Connect';
import Certification from './content/Certification';
import Skills from './content/Skills';
// import ProfessionalExperience from './content/ProfessionalExperience';
import Profchrono from './content/ProfChrono';
import CommunityWork from './content/CommunityWork';
import './MainContent.css';

const sections = [
  { id: "projects", icon: "./navigation-icons/achievement.svg", title: "Projects", content: <ProjectSection /> },
  { id: "certification", icon: "./navigation-icons/certification.svg", title: "Certification", content: <Certification />},
  { id: "skill", icon: "./navigation-icons/skill.svg", title: "Skills", content: <Skills />},
  { id: "contract", icon: "./navigation-icons/contract.svg", title: "Professional Experience", content: <Profchrono />},
  { id: "Research", icon: "./navigation-icons/laboratory.svg", title: "Research", content: <ScientificResearch />},
  { id: "community", icon: "./navigation-icons/leader.svg", title: "Community Work" , content: <CommunityWork />},
  { id: "connect", icon: "./navigation-icons/connect.svg", title: "Get in touch", content: <Connect />}
];

const MainContent = () => (
  <main className='main-content'>
    {sections.map((section, index) => (
      <Section key={index} id={section.id} icon={section.icon} title={section.title} content={section?.content}/>
    ))}
  </main>
);

export default MainContent;
