import React, { useEffect, useState } from "react";
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './ProfChrono.css';
import JobExperience from "./JobExperience";

export const calculateDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const now = endDate ? new Date(endDate) : new Date();
  const years = now.getFullYear() - start.getFullYear();
  const months = now.getMonth() - start.getMonth();

  let totalMonths = years * 12 + months;
  const displayYears = Math.floor(totalMonths / 12);
  const displayMonths = totalMonths % 12;
  const monthsString = displayMonths > 1 ? 'months' : 'month';
  const yearString = displayYears > 1 ? 'years' : 'year';
  const showYears = displayYears > 0 ? true : false;
  const displayDuration = `${showYears ? `${displayYears} ${yearString} ` : ''}${displayMonths} ${monthsString}`;

  return displayDuration;
};

const ProfChrono = () => {
  const [duration, setDuration] = useState('');

  useEffect(() => {
    const startDate = '2023-9-15';
    const endDate = new Date();
    const calculatedDuration = calculateDuration(startDate, endDate);
    setDuration(calculatedDuration);
  }, []);

  const experiences = [
    {
      title: "Data Scientist",
      company: "Artefact · Full-time",
      date: `Sep 2023 - Present · ${duration}`,
      startDate: "2023-09-15",
      endDate: null,
      duration: calculateDuration("2023-09-15"),
      startDateString: "Sep 2023",
      endDateString: "Present",
      location: "Utrecht, Netherlands · Hybrid",
      logo: "./content-icons/artefact.svg",
      description: `
        Working as a Data Scientist at Artefact, I am responsible for applying data analysis and machine learning techniques to derive insights and create data-driven solutions that meet the company's goals.
      `
    },
    {
      title: "Software Developer",
      company: "Grant Thornton Netherlands · Part-time",
      date: "Nov 2020 - Sep 2023 · 2 years 11 months",
      startDate: "2020-11-01",
      endDate: "2023-09-15",
      duration: calculateDuration("2020-11-01", "2023-09-15"),
      startDateString: "Nov 2020",
      endDateString: "Sep 2023",
      location: "Gouda, South Holland, Netherlands · Hybrid",
      logo: "./content-icons/gt.svg",
      description: `
        During my tenure at Grant Thornton, I embraced the challenge of balancing part-time availability with the pursuit of excellence. Leveraging my expertise, I actively contributed to various projects, leaving a tangible mark on the company's operations. Here's how I made an impact:
        - Collaborated with non-technical colleagues, translating business challenges into actionable technical solutions. This involved gathering requirements and employing an iterative approach to develop solutions tailored to the end-user needs.
        - Demonstrated proficiency in full-stack development using Outsystems, delivering multiple successful projects. Notable among these was the Tax Reporting System, which evolved into a product released to clients.
        - Designed and implemented an internally customized surveying system, aligning closely with the company's specific requirements and workflows.
        - Contributed significantly to other key projects, including the development of a financial payment system and the automation of invoice generation processes.
        - Contributed in the development of a system for parsing financial documents, enhancing efficiency and accuracy in data processing.
        Throughout these endeavors, I remained committed to delivering high-quality results and suggesting innovative solutions within the organization.
      `
    },
    {
      title: "Software Developer",
      company: "ScenWise · Internship",
      date: "May 2020 - Jul 2020 · 3 months",
      startDate: "2020-05-01",
      endDate: "2020-07-14",
      duration: "3 months",
      startDateString: "May 2020",
      endDateString: "July 2020",
      location: "Netherlands · Remote",
      logo: "./content-icons/scenwise.svg",
      description: `
        The goal of the internship is to deliver a traffic data analysis web application. I contributed significantly on the back-end, working with Java as the programming language and using Rest API, Spring framework, and Database.
      `
    },
  ];

  return (
    <VerticalTimeline
      layout='1-column'
      className="vertical-timeline-custom-line"
    >
      {experiences.map((experience, index) => (
        <JobExperience
          key={index}
          experience={experience}
          highlight={index === 0}
        />
      ))}
    </VerticalTimeline>
  );
};

export default ProfChrono;