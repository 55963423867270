import React from 'react';
import Header from './components/Header.js';
import MainContent from './components/MainContent';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import './App.css';
import BackgroundIcons from './components/BackgroundIcons';

function App() {
  return (
    <div className="App">
      <BackgroundIcons />
      <Header />
      <MainContent />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
