import React, { useState } from 'react';

const communityWork = [
    {
        title: 'Volunteering',
        // content: 'I have been a volunteer for nearly 2 years, helping the community with various projects.',
        icon: './content-icons/interact.svg'
    },
];

const CommunityWork = () => {
    const [selectedWork, setSelectedWork] = useState(null);

    const handleCardClick = (index) => {
        setSelectedWork(index === selectedWork ? null : index);
    };

    return (
        <div className="project-grid">
            {communityWork.map((work, index) => (
                <div 
                    key={index} 
                    className={`project-item ${selectedWork === index ? 'active' : ''}`} 
                    onClick={() => handleCardClick(index)}
                >
                    <div className="project-image"> 
                        <img src={work.icon} alt={work.title} />
                    </div>
                    <h3>{work.title}</h3>
                </div>
            ))}
            {/* {selectedWork !== null && (
                <div className="project-description">
                    <p>{communityWork[selectedWork].content}</p>
                </div>
            )} */}
        </div>
    );
};

export default CommunityWork;
