import React, { useState } from 'react';
import './Certification.css';
import useWindowSize from "../useWindowSize";

const certifications = [
    {
        title: "Master of Science in Artificial Intelligence",
        logo: "./content-icons/tudelft.png",
        description: "The Master of Science in Computer Science program at Delft University of Technology is a two-year program that focuses on the study of computer science, with a specialization in software technology. The program covers a wide range of topics, including software engineering, programming languages, algorithms, and data structures. Students are required to complete a thesis project in their second year, which involves conducting original research in a specific area of computer science. The program also includes courses in professional skills, such as project management and communication, to prepare students for a career in the field of computer science."
    },
    {
        title: "Bachelor of Science in Computer Science",
        logo: "./content-icons/tudelft.png",
        description: "The Bachelor of Science in Computer Science program at Delft University of Technology is a three-year program that provides students with a solid foundation in computer science. The program covers a wide range of topics, including programming, algorithms, data structures, and computer systems. Students also have the opportunity to take elective courses in areas such as artificial intelligence, software engineering, and computer graphics. The program includes a final project in the form of a bachelor thesis, where students work on a research project under the supervision of a faculty member. The program also includes courses in professional skills, such as project management and communication, to prepare students for a career in the field of computer science."
    }
];

const Certification = () => {
    const { width } = useWindowSize();
    const [selectedCertification, setSelectedCertification] = useState(null);

    const handleCardClick = (index) => {
        setSelectedCertification(index === selectedCertification ? null : index);
    };

    return (
        <div className="certifications-container">
            {certifications.map((certification, index) => (
                <div 
                    key={index} 
                    className={`certification-card ${selectedCertification === index ? 'active' : ''}`} 
                    onClick={() => handleCardClick(index)}
                >
                    <img src={certification.logo} alt={certification.title} />
                    <h3>{certification.title}</h3>
                    {width < 800 && selectedCertification === index &&
                    <div className="project-details">
                        <p>{certifications[index].description}</p>
                    </div>
                    }
                </div>
            ))}
            {selectedCertification !== null && width >= 800 && (
                <div className="certification-description">
                    <p>{certifications[selectedCertification].description}</p>
                </div>
            )}
        </div>
    );
};

export default Certification;
