import React from 'react';
import './Bio.css';

const Bio = () => (
  <div className='bio-frame'>
    <img src="./profile.svg" alt="profile" className='bio-img'/>
    <div className='bio'>
      <h1>Ziad Nawar, MSc</h1>
      <h3>Data Scientist</h3>
      <hr />
      <p>
        Passionate and collaborative computer science professional with expertise in software
        development, machine learning, and statistical methods. Able to lead a diverse team and deliver
        successful projects with demonstrated experience. Possesses research skills and a strong foundation in
        AI/ML, and software development, eager to contribute to innovative projects and further
        develop leadership abilities.
      </p>
    </div>
  </div>
);

export default Bio;
