import React, { useEffect } from 'react';
import './NavigationIcons.css';

const navItems = [
  { href: "#projects", dataText: "Projects", src: "./navigation-icons/achievement.svg" },
  { href: "#certification", dataText: "Certification", src: "./navigation-icons/certification.svg" },
  { href: "#skill", dataText: "Skills", src: "./navigation-icons/skill.svg" },
  { href: "#contract", dataText: "Professional Experience", src: "./navigation-icons/contract.svg" },
  { href: "#Research", dataText: "Research", src: "./navigation-icons/laboratory.svg" },
  { href: "#community", dataText: "Community Work", src: "./navigation-icons/leader.svg" },
  { href: "#connect", dataText: "Get in touch", src: "./navigation-icons/connect.svg" }
];

const NavigationIcons = () => {
  useEffect(() => {
    const anchorLinks = document.querySelectorAll('.Navigation-icons a');
    anchorLinks.forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      });
    });
  }, []);

  return (
    <div className='Navigation-icons'>
      {navItems.map((item, index) => (
        <a key={index} href={item.href} data-text={item.dataText}>
          <img src={item.src} alt={item.dataText} />
        </a>
      ))}
    </div>
  );
};

export default NavigationIcons;
