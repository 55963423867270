import React, { useEffect } from 'react';

const ScrollToTopButton = () => {
  useEffect(() => {
    const scrollToTopBtn = document.getElementById('scrollToTopBtn');
    scrollToTopBtn.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }, []);

  return (
    <button id="scrollToTopBtn" className="scroll-to-top">
      <img src="./navigation-icons/top.svg" alt="scroll to top" style={{ width: "5vh" }} />
    </button>
  );
};

export default ScrollToTopButton;
