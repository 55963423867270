import React from 'react';
import './Section.css';

function Section({ id, icon, title, content }) {
  return (
    <section id={id}>
    <div className="section">
      <div className='section-header'>
        <img src={icon} alt={id} />
        <h1>{title}</h1>
      </div>
      <div className='section-content'>
        {content}
      </div>
    </div>
    </section>
  );
}

export default Section;