import React from 'react';
import './Connect.css';

const Connect = () => (
    <div className='Connect-content'>
        <div>
            <a href="https://www.linkedin.com/in/ziad-nawar" target="_blank" rel='noreferrer noopener'> 
                <img src="./content-icons/linkedin.svg" alt="linkedin" />
            </a>    
        </div>
        <div>
        <a href="https://www.github.com/ziadnawar" target="_blank" rel='noreferrer noopener'>
            <img src="./content-icons/github.svg" alt="github" />
        </a>
        </div>
        <a href="https://www.twitter.com/ziadnawar" target="_blank" rel='noreferrer noopener'>
            <img src="./content-icons/X.svg" alt="twitter" /> 
        </a>

    </div>

  );
  
  export default Connect;